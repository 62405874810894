<template>
  <div class="container-notifications-show">
    <template v-if="project != null && events != null">
      <div class="header-notification">
        <div class="header-notification-content">
          <h5 class="header-notification-full-name">{{ project.full_name }}</h5>
          <p class="header-notification-document">Cédula: {{ project.person_id }}</p>
          <p class="header-notification-current-activity">Cargo: {{project.current_activity}}</p>
        </div>
      </div>
      <div class="notifications-show">
        <div :class="`colum-notifications ${event.event_type_key}`" v-for="event in events.filter( event => event.childrens.length > 0)" :key="event.event_type">        
          <div class="container-notifications container-notifications-details">          
            <div class="container-notification-show">
              <div class="container-title-column">
                <h4 class="title-column-notification">{{event.event_type}}</h4>
              </div>              
              <div class="information-show-notification" v-for="notification in event.purify_childrens" :key="notification.id">
                <div class="container-summary">
                  <div class="notification-content event-notification">
                    <small class="name-key-content-notification-show">{{ notification.name_key_translate }}</small>
                    <div class="labels-event-type" v-if="notification.assignment != null && event.event_type_key == 'project_finished'">
                      <span :class="`label-event-type info-event-type qualification-label-${qualificationColor(notification.assignment.qualification)}`" v-if="notification.assignment.qualification != null">Calificación: {{notification.assignment.qualification}}</span>
                      <span class="label-event-type info-event-type" v-if="notification.assignment.status_name != null">{{notification.assignment.status_name}}</span>
                    </div>
                    <div class="notification-ago"><small class="text-ago-notification">{{ notification.timestamp | convertTimeStamp }}</small></div>
                  </div>
                  <div class="icon-dropdown" @click.prevent="showDetails(event.event_type_key, notification.childrens[0].name_key)" v-if="notification.childrens[0]['relations'].length > 0">
                    <vs-icon size="medium" icon="expand_more" :id="`${event.event_type_key}-icon-more-${notification.childrens[0].name_key}`" style="display: block;"></vs-icon>
                    <vs-icon size="medium" icon="expand_less" :id="`${event.event_type_key}-icon-less-${notification.childrens[0].name_key}`" style="display: none;"></vs-icon>
                  </div>                  
                </div>
                <div class="container-details" :id="`${event.event_type_key}-container-details-${notification.childrens[0].name_key}`" style="display: none;">
                  <div class="reference-notifications" v-for="reference in notification.childrens[0]['relations']" :key="reference.id">
                    <div class="reference-notification" v-if="notification.childrens[0]['relation'] == 'academic_references' || notification.childrens[0]['relation'] == 'assignment_academics'">
                      <div class="reference-notification-row">
                        <p class="icon-item-notification">-</p>
                      </div>
                      <div class="reference-notification-content">
                        <div class="content-information-reference">
                          <p class="label-name">Institución:</p>
                          <p class="content-name"> {{reference.name_institution}}</p>                        
                        </div>
                        <div class="content-information-reference name-title">
                          <p class="label-name">Título:</p>
                          <p class="content-name"> {{reference.name_title}}</p>                        
                        </div>                        
                      </div>
                    </div>
                    <div class="reference-notification" v-if="notification.childrens[0]['relation'] == 'laboral_references' || notification.childrens[0]['relation'] == 'assignment_laborals'">
                      <div class="reference-notification-row">
                        <p class="icon-item-notification">-</p>
                      </div>
                      <div class="reference-notification-content">
                        <div class="content-information-reference">
                          <p class="label-name">Empresa:</p>
                          <p class="content-name"> {{reference.company_name}}</p>                        
                        </div>
                        <div class="content-information-reference name-title">
                          <p class="label-name">Cargo:</p>
                          <p class="content-name"> {{reference.charge}}</p>                        
                        </div>                        
                        <div class="content-information-reference name-title">
                          <p class="label-name">Motivo de retiro:</p>
                          <p class="content-name"> {{reference.retirement}}</p>                        
                        </div>                                                                
                        <div class="content-information-reference name-title">
                          <p class="label-name">Nombre de la persona contactada:</p>
                          <p class="content-name"> {{reference.contact_name}}</p>                        
                        </div>                                            
                        <div class="content-information-reference name-title">
                          <p class="label-name">Cargo de la persona contactada:</p>
                          <p class="content-name"> {{reference.contact_charge}}</p>                        
                        </div>                                                
                        <div class="content-information-reference name-title">
                          <p class="label-name">Calificación:</p>
                          <p class="content-name"> {{reference.qualification}}</p>                        
                        </div>                                                
                        <div class="content-information-reference name-title">
                          <p class="label-name">Descripción:</p>
                          <p class="content-name"> {{reference.personality}}</p>                        
                        </div>                                                
                        <div class="content-information-reference name-title">
                          <p class="label-name">Observaciones:</p>
                          <p class="content-name"> {{reference.observations}}</p>                        
                        </div>                                                                                                                            
                      </div>
                    </div>
                    <div class="reference-notification" v-if="notification.childrens[0]['relation'] == 'family_references' || notification.childrens[0]['relation'] == 'assignment_famils'">
                      <div class="reference-notification-row">
                        <p class="icon-item-notification">-</p>
                      </div>
                      <div class="reference-notification-content">
                        <div class="content-information-reference">
                          <p class="label-name">Referencia:</p>
                          <p class="content-name"> {{reference.family_reference_full_name}}</p>                        
                        </div>
                        <div class="content-information-reference name-title">
                          <p class="label-name">Tipo de relación:</p>
                          <p class="content-name"> {{reference.family_reference_relationship}}</p>                        
                        </div>                        
                        <div class="content-information-reference name-title">
                          <p class="label-name">Calificación:</p>
                          <p class="content-name"> {{reference.qualification}}</p>                        
                        </div>                                                
                        <div class="content-information-reference name-title">
                          <p class="label-name">Descripción:</p>
                          <p class="content-name"> {{reference.personality}}</p>                        
                        </div>                                                                                        
                        <div class="content-information-reference name-title">
                          <p class="label-name">Observaciones:</p>
                          <p class="content-name"> {{reference.family_reference_observations}}</p>                        
                        </div>                                                                        
                      </div>
                    </div>
                    <div class="reference-notification" v-if="notification.childrens[0]['relation'] == 'personal_references' || notification.childrens[0]['relation'] == 'assignment_personals'">
                      <div class="reference-notification-row">
                        <p class="icon-item-notification">-</p>
                      </div>
                      <div class="reference-notification-content">
                        <div class="content-information-reference">
                          <p class="label-name">Referencia:</p>
                          <p class="content-name"> {{reference.personal_reference_name}}</p>                        
                        </div>
                        <div class="content-information-reference name-title">
                          <p class="label-name">Tipo de relación:</p>
                          <p class="content-name"> {{reference.personal_reference_relation_type}}</p>                        
                        </div>                        
                        <div class="content-information-reference name-title">
                          <p class="label-name">Calificación:</p>
                          <p class="content-name"> {{reference.qualification}}</p>                        
                        </div>                                                
                        <div class="content-information-reference name-title">
                          <p class="label-name">Descripción:</p>
                          <p class="content-name"> {{reference.personality}}</p>                        
                        </div>                                                                                        
                        <div class="content-information-reference name-title">
                          <p class="label-name">Observaciones:</p>
                          <p class="content-name"> {{reference.family_reference_observations}}</p>                        
                        </div>                                                                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>        
        </div>
      </div>      
    </template>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import api from '@/mixins/api.js'
  import moment from 'moment'
  import 'moment/locale/es'
  moment.locale('es')
  export default {
    mixins: [api],    
    data() {
      return {
        project: null,
        events: null
      }
    },
    computed: {
      ...mapGetters(['userInfo', 'currentProjectNotification']),
    },    
    methods: {
      async getNotifications() {
        const request = await this.requestGetWithoutContent(`${this.url()}api/v1/clients/event_notifications?project_id=${this.currentProjectNotification}`, {})
        if (request.status == 200) {
          this.project = request.data.project
          this.events = request.data.events
        }
        document.querySelector('.container-notifications-show').click()
      },      
      showDetails(eventType, nameKey) {
        const idContainer = `${eventType}-container-details-${nameKey}`
        const currentStateIdContainer = document.querySelector(`#${idContainer}`).style.display
        if (currentStateIdContainer == 'none') document.querySelector(`#${idContainer}`).style.display = 'block'
        if (currentStateIdContainer == 'block') document.querySelector(`#${idContainer}`).style.display = 'none'

        const idIconMore = `${eventType}-icon-more-${nameKey}`
        const idIconLess = `${eventType}-icon-less-${nameKey}`

        const currentStateidIconMore = document.querySelector(`#${idIconMore}`).style.display
        const currentStateidIconLess = document.querySelector(`#${idIconLess}`).style.display

        if (currentStateidIconMore == 'none') document.querySelector(`#${idIconMore}`).style.display = 'block'
        if (currentStateidIconMore == 'block') document.querySelector(`#${idIconMore}`).style.display = 'none'

        if (currentStateidIconLess == 'none') document.querySelector(`#${idIconLess}`).style.display = 'block'
        if (currentStateidIconLess == 'block') document.querySelector(`#${idIconLess}`).style.display = 'none'
      },
      qualificationColor(qualification) {
        if (qualification <= 3) {
          return 'danger'
        }
        if (qualification == 4) {
          return 'warning'
        }        
        if (qualification > 4) {
          return 'success'
        }                
      }      
    },
    watch: {
      currentProjectNotification(newValue, oldValue) {
        if (newValue != oldValue) this.getNotifications()
      }      
    },
    filters: {
      convertTimeStamp: function(timestamp) {
        var date = new Date(timestamp * 1000);
        return moment(date, "YYYYMMDD").fromNow();
      }
    },    
    mounted () {
      this.getNotifications()
    },
  }
</script>
