<template>
  <div class="page-notifications">
    <div class="container-switch-notification">
      <label for="" class="label-switch-notification">En columna / Por eventos</label>
      <vs-switch v-model="switchEventsColumns" @click.prevent="switchEventsColumns = !switchEventsColumns"/>
    </div>
    <NotificationsColumn  v-if="switchEventsColumns"/>
    <NotificationsColumns  v-else/>
  </div>
</template>

<script>
  import NotificationsColumns from '@/components-globalwork/notifications/NotificationsColumns.vue'
  import NotificationsColumn from '@/components-globalwork/notifications/NotificationsColumn.vue'
  export default {
    data() {
      return {
        switchEventsColumns: true
      }
    },
    components: {
      NotificationsColumns,
      NotificationsColumn,
    },
  }
</script>

<style scoped>

</style>