var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-notifications-show" },
    [
      _vm.project != null && _vm.events != null
        ? [
            _c("div", { staticClass: "header-notification" }, [
              _c("div", { staticClass: "header-notification-content" }, [
                _c("h5", { staticClass: "header-notification-full-name" }, [
                  _vm._v(_vm._s(_vm.project.full_name)),
                ]),
                _c("p", { staticClass: "header-notification-document" }, [
                  _vm._v("Cédula: " + _vm._s(_vm.project.person_id)),
                ]),
                _c(
                  "p",
                  { staticClass: "header-notification-current-activity" },
                  [_vm._v("Cargo: " + _vm._s(_vm.project.current_activity))]
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "notifications-show" },
              _vm._l(
                _vm.events.filter(function (event) {
                  return event.childrens.length > 0
                }),
                function (event) {
                  return _c(
                    "div",
                    {
                      key: event.event_type,
                      class: "colum-notifications " + event.event_type_key,
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "container-notifications container-notifications-details",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "container-notification-show" },
                            [
                              _c(
                                "div",
                                { staticClass: "container-title-column" },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass: "title-column-notification",
                                    },
                                    [_vm._v(_vm._s(event.event_type))]
                                  ),
                                ]
                              ),
                              _vm._l(
                                event.purify_childrens,
                                function (notification) {
                                  return _c(
                                    "div",
                                    {
                                      key: notification.id,
                                      staticClass:
                                        "information-show-notification",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "container-summary" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "notification-content event-notification",
                                            },
                                            [
                                              _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "name-key-content-notification-show",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      notification.name_key_translate
                                                    )
                                                  ),
                                                ]
                                              ),
                                              notification.assignment != null &&
                                              event.event_type_key ==
                                                "project_finished"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "labels-event-type",
                                                    },
                                                    [
                                                      notification.assignment
                                                        .qualification != null
                                                        ? _c(
                                                            "span",
                                                            {
                                                              class:
                                                                "label-event-type info-event-type qualification-label-" +
                                                                _vm.qualificationColor(
                                                                  notification
                                                                    .assignment
                                                                    .qualification
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Calificación: " +
                                                                  _vm._s(
                                                                    notification
                                                                      .assignment
                                                                      .qualification
                                                                  )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      notification.assignment
                                                        .status_name != null
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "label-event-type info-event-type",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  notification
                                                                    .assignment
                                                                    .status_name
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "notification-ago",
                                                },
                                                [
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-ago-notification",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "convertTimeStamp"
                                                          )(
                                                            notification.timestamp
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          notification.childrens[0]["relations"]
                                            .length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "icon-dropdown",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.showDetails(
                                                        event.event_type_key,
                                                        notification
                                                          .childrens[0].name_key
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("vs-icon", {
                                                    staticStyle: {
                                                      display: "block",
                                                    },
                                                    attrs: {
                                                      size: "medium",
                                                      icon: "expand_more",
                                                      id:
                                                        event.event_type_key +
                                                        "-icon-more-" +
                                                        notification
                                                          .childrens[0]
                                                          .name_key,
                                                    },
                                                  }),
                                                  _c("vs-icon", {
                                                    staticStyle: {
                                                      display: "none",
                                                    },
                                                    attrs: {
                                                      size: "medium",
                                                      icon: "expand_less",
                                                      id:
                                                        event.event_type_key +
                                                        "-icon-less-" +
                                                        notification
                                                          .childrens[0]
                                                          .name_key,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "container-details",
                                          staticStyle: { display: "none" },
                                          attrs: {
                                            id:
                                              event.event_type_key +
                                              "-container-details-" +
                                              notification.childrens[0]
                                                .name_key,
                                          },
                                        },
                                        _vm._l(
                                          notification.childrens[0][
                                            "relations"
                                          ],
                                          function (reference) {
                                            return _c(
                                              "div",
                                              {
                                                key: reference.id,
                                                staticClass:
                                                  "reference-notifications",
                                              },
                                              [
                                                notification.childrens[0][
                                                  "relation"
                                                ] == "academic_references" ||
                                                notification.childrens[0][
                                                  "relation"
                                                ] == "assignment_academics"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "reference-notification",
                                                      },
                                                      [
                                                        _vm._m(0, true),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "reference-notification-content",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Institución:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.name_institution
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Título:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.name_title
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                notification.childrens[0][
                                                  "relation"
                                                ] == "laboral_references" ||
                                                notification.childrens[0][
                                                  "relation"
                                                ] == "assignment_laborals"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "reference-notification",
                                                      },
                                                      [
                                                        _vm._m(1, true),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "reference-notification-content",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Empresa:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.company_name
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Cargo:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.charge
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Motivo de retiro:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.retirement
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Nombre de la persona contactada:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.contact_name
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Cargo de la persona contactada:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.contact_charge
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Calificación:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.qualification
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Descripción:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.personality
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Observaciones:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.observations
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                notification.childrens[0][
                                                  "relation"
                                                ] == "family_references" ||
                                                notification.childrens[0][
                                                  "relation"
                                                ] == "assignment_famils"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "reference-notification",
                                                      },
                                                      [
                                                        _vm._m(2, true),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "reference-notification-content",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Referencia:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.family_reference_full_name
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Tipo de relación:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.family_reference_relationship
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Calificación:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.qualification
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Descripción:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.personality
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Observaciones:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.family_reference_observations
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                notification.childrens[0][
                                                  "relation"
                                                ] == "personal_references" ||
                                                notification.childrens[0][
                                                  "relation"
                                                ] == "assignment_personals"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "reference-notification",
                                                      },
                                                      [
                                                        _vm._m(3, true),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "reference-notification-content",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Referencia:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.personal_reference_name
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Tipo de relación:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.personal_reference_relation_type
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Calificación:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.qualification
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Descripción:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.personality
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "content-information-reference name-title",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "label-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Observaciones:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "content-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          reference.family_reference_observations
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  )
                }
              ),
              0
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reference-notification-row" }, [
      _c("p", { staticClass: "icon-item-notification" }, [_vm._v("-")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reference-notification-row" }, [
      _c("p", { staticClass: "icon-item-notification" }, [_vm._v("-")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reference-notification-row" }, [
      _c("p", { staticClass: "icon-item-notification" }, [_vm._v("-")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reference-notification-row" }, [
      _c("p", { staticClass: "icon-item-notification" }, [_vm._v("-")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }