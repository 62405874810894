var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-notifications-show" },
    [
      _vm.project != null && _vm.events != null
        ? [
            _c("div", { staticClass: "header-notification" }, [
              _c("div", { staticClass: "header-notification-content" }, [
                _c("h5", { staticClass: "header-notification-full-name" }, [
                  _vm._v(_vm._s(_vm.project.full_name)),
                ]),
                _c("p", { staticClass: "header-notification-document" }, [
                  _vm._v("Cédula: " + _vm._s(_vm.project.person_id)),
                ]),
                _c(
                  "p",
                  { staticClass: "header-notification-current-activity" },
                  [_vm._v("Cargo: " + _vm._s(_vm.project.current_activity))]
                ),
              ]),
            ]),
            _c("div", { staticClass: "container-column-notifications" }, [
              _c(
                "div",
                { staticClass: "column-notifications" },
                _vm._l(_vm.events, function (event) {
                  return _c(
                    "div",
                    { key: event.id, staticClass: "item-notification" },
                    [
                      _c("div", { staticClass: "container-summary" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "notification-content event-notification",
                          },
                          [
                            _c(
                              "small",
                              {
                                staticClass:
                                  "name-key-content-notification-show",
                              },
                              [_vm._v(_vm._s(event.name_key_translate))]
                            ),
                            event.assignment != null &&
                            event.event_type == "project_finished"
                              ? _c(
                                  "div",
                                  { staticClass: "labels-event-type" },
                                  [
                                    event.assignment.qualification != null
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              "label-event-type info-event-type qualification-label-" +
                                              _vm.qualificationColor(
                                                event.assignment.qualification
                                              ),
                                          },
                                          [
                                            _vm._v(
                                              "Calificación: " +
                                                _vm._s(
                                                  event.assignment.qualification
                                                )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    event.assignment.status_name != null
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "label-event-type info-event-type",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                event.assignment.status_name
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "notification-ago" }, [
                              _c(
                                "small",
                                { staticClass: "text-ago-notification" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(event.created_at)
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        event.references.length > 0
                          ? _c(
                              "div",
                              {
                                staticClass: "icon-dropdown",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showDetails(
                                      event.event_type,
                                      event.name_key
                                    )
                                  },
                                },
                              },
                              [
                                _c("vs-icon", {
                                  staticStyle: { display: "block" },
                                  attrs: {
                                    size: "medium",
                                    icon: "expand_more",
                                    id:
                                      event.event_type +
                                      "-icon-more-" +
                                      event.name_key,
                                  },
                                }),
                                _c("vs-icon", {
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    size: "medium",
                                    icon: "expand_less",
                                    id:
                                      event.event_type +
                                      "-icon-less-" +
                                      event.name_key,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "container-details container-details-notification-column",
                          staticStyle: { display: "none" },
                          attrs: {
                            id:
                              event.event_type +
                              "-container-details-" +
                              event.name_key,
                          },
                        },
                        _vm._l(event.references, function (reference) {
                          return _c(
                            "div",
                            {
                              key: reference.id,
                              staticClass: "reference-notifications",
                            },
                            [
                              event.relation == "academic_references" ||
                              event.relation == "assignment_academics"
                                ? _c(
                                    "div",
                                    { staticClass: "reference-notification" },
                                    [
                                      _vm._m(0, true),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "reference-notification-content",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Institución:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.name_institution
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Título:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.name_title
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              event.relation == "laboral_references" ||
                              event.relation == "assignment_laborals"
                                ? _c(
                                    "div",
                                    { staticClass: "reference-notification" },
                                    [
                                      _vm._m(1, true),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "reference-notification-content",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Empresa:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.company_name
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Cargo:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(reference.charge)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Motivo de retiro:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.retirement
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [
                                                  _vm._v(
                                                    "Nombre de la persona contactada:"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.contact_name
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [
                                                  _vm._v(
                                                    "Cargo de la persona contactada:"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.contact_charge
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Calificación:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.qualification
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Descripción:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.personality
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Observaciones:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.observations
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              event.relation == "family_references" ||
                              event.relation == "assignment_famils"
                                ? _c(
                                    "div",
                                    { staticClass: "reference-notification" },
                                    [
                                      _vm._m(2, true),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "reference-notification-content",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Referencia:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.family_reference_full_name
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Tipo de relación:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.family_reference_relationship
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Calificación:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.qualification
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Descripción:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.personality
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Observaciones:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.family_reference_observations
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              event.relation == "personal_references" ||
                              event.relation == "assignment_personals"
                                ? _c(
                                    "div",
                                    { staticClass: "reference-notification" },
                                    [
                                      _vm._m(3, true),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "reference-notification-content",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Referencia:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.personal_reference_name
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Tipo de relación:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.personal_reference_relation_type
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Calificación:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.qualification
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Descripción:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.personality
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-information-reference name-title",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-name" },
                                                [_vm._v("Observaciones:")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "content-name" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        reference.family_reference_observations
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reference-notification-row" }, [
      _c("p", { staticClass: "icon-item-notification" }, [_vm._v("-")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reference-notification-row" }, [
      _c("p", { staticClass: "icon-item-notification" }, [_vm._v("-")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reference-notification-row" }, [
      _c("p", { staticClass: "icon-item-notification" }, [_vm._v("-")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reference-notification-row" }, [
      _c("p", { staticClass: "icon-item-notification" }, [_vm._v("-")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }