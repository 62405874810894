var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-notifications" },
    [
      _c(
        "div",
        { staticClass: "container-switch-notification" },
        [
          _c(
            "label",
            { staticClass: "label-switch-notification", attrs: { for: "" } },
            [_vm._v("En columna / Por eventos")]
          ),
          _c("vs-switch", {
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.switchEventsColumns = !_vm.switchEventsColumns
              },
            },
            model: {
              value: _vm.switchEventsColumns,
              callback: function ($$v) {
                _vm.switchEventsColumns = $$v
              },
              expression: "switchEventsColumns",
            },
          }),
        ],
        1
      ),
      _vm.switchEventsColumns
        ? _c("NotificationsColumn")
        : _c("NotificationsColumns"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }